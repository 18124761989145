<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div>
    <div class="controls mb-small">
      <h4>{{ test.name }}</h4>
      <div class="discrete">
        <template v-if="isPast(test.start) && isFuture(test.end)" >
          <app-icon class="discrete" name="timer-sand"></app-icon>&nbsp;
          <translate class="discrete" translate-context="*/*/*">Ongoing</translate>
        </template>

        <template v-else-if="isFuture(test.start)" >
          <app-icon class="discrete" name="calendar"></app-icon>&nbsp;
          <translate class="discrete" translate-context="*/*/*" key="2">Planned</translate>

        </template>
        <template v-else >
          <app-icon class="discrete" name="check"></app-icon>&nbsp;
          <translate class="discrete" translate-context="*/*/*" key="3">Completed</translate>
        </template>
        ·
        <template v-if="test.enabled">
          <a href="" @click.prevent="showAssignModal = true">
            <app-icon name="upload"></app-icon>
            <translate translate-context="*/Button/Verb">Assign…</translate>
          </a>
          ·
          <app-modal v-if="showAssignModal" @close="showAssignModal = false" :submit-action="assign">
            <template v-slot:header>
              <h3>
                <translate translate-context="*/*/*" :translate-params="{name: test.name}">Assign users to A/B test %{ name }</translate>
              </h3>
            </template>
            <p>
              <translate translate-context="*/*/*">Use the form below to assign a batch of users to this test.</translate>
            </p>
            
            <form @click.prevent="" id="submit-batch" required>
              <div class="field">
                <label for="batch-users">
                  <translate translate-context="*/*/*">Users</translate>
                </label>
                <textarea name="batch-users" id="batch-users" rows="15" v-model="users"></textarea>
                <p>
                  <translate translate-context="Help/*/Paragraph">
                    You can copy paste a list of users hash or session name separated by coma, semicolon, spaces or line breaks.
                  </translate>
                </p>
              </div>
              <field-errors :errors="errors.nonFieldErrors" />
            </form>
            <template v-slot:action-button>
              <app-button
                :is-loading="isAssigning"
                @click.prevent="assign"
                form="submit-batch"
                :disabled="stringToArray(users).length === 0">
                <translate
                  translate-context="*/*/*"
                  :translate-params="{count: stringToArray(users).length}"
                  translate-plural="Assign %{ count } users"
                  :translate-n="stringToArray(users).length">
                  Assign %{ count } user
                </translate>
              </app-button>
            </template>
          </app-modal>
        </template>
        <router-link
          :to="{name: 'dashboard.users', query: {abtest: test.id}, params: {group: $store.state.selectedGroupId}}">
          <translate
            translate-context="*/*/*"
            :translate-params="{count: test.users}"
            translate-plural="%{ count } users"
            :translate-n="test.users">
            %{ count } user
          </translate>
        </router-link>
      </div>
    </div>
    <div class="stackable row mb-regular">
      <div>
        <h5>
          <translate translate-context="*/*/*">Start</translate>
        </h5>
        <time class="discrete" :title="test.start" :datetime="test.start">
          {{ prettyDate(test.start) }}
        </time>
      </div>
      <div>
        <h5>
          <translate translate-context="*/*/*">End</translate>
        </h5>
        <time class="discrete" :title="test.end" :datetime="test.end">
          {{ prettyDate(test.end) }}
        </time>
      </div>
    </div>
    <div
      class="mb-regular"
      v-if="test.goal_agents && test.goal_hours && test.goal_progress && test.goal_progress.total && isPast(test.start)">
      <h5>
        <translate translate-context="*/*/*">Collected data</translate>
      </h5>
      <div :class="['progress', {warning: test.goal_progress.total < expectedProgress}]" :data-label="`${truncate(test.goal_progress.total * 100, {length: 5, omission: ''})}%`">
        <span class="value" :style="`width:${test.goal_progress.total * 100}%;`"></span>
      </div>
      <template v-if="isPast(test.start) && isFuture(test.end)">
        <p class="discrete" v-if="test.goal_progress.total >= expectedProgress">
          <translate translate-context="Abtest/*/*">This test is on track, we are receiving sufficient data for analysis.</translate>
        </p>
        <p class="discrete" v-else>
          <translate translate-context="Abtest/*/*">Not enough users are properly setup to keep track with the expected end date. Please check the Tech Reports and/or the AB Test Reports to know the details of encountered errors. Otherwise contact us to talk about it!</translate>
        </p>
      </template>
    </div>
    <div>
      <h5>
        <translate translate-context="*/*/*">Reports</translate>
      </h5>
      <div :title="labels.reportTitle" v-if="test.report_email">
        <app-icon name="bell" class="discrete"></app-icon>&nbsp;
        <a class="break" :href="`mailto:${test.report_email}`">{{ test.report_email }}</a>
      </div>
      <div class="discrete">
        <download-link :api-url="`it/abtests/${test.id}/csv`" :filename="`${test.name}.csv`">
          <translate translate-context="*/*/*/Verb">Download CSV</translate>
        </download-link>
      </div>
    </div>
    <div class="mt-regular">
      <div class="controls">
        <a href="" class="discrete link" @click.prevent="expanded = !expanded">
          <template v-if="expanded">
            <app-icon name="magnify-minus-outline"></app-icon>&nbsp;
            <translate translate-context="*/*/*/Verb">Hide details</translate>
          </template>
          <template v-else>
            <app-icon name="magnify-plus-outline"></app-icon>&nbsp;
            <translate translate-context="*/*/*/Verb">Show details</translate>
          </template>
        </a>
        <router-link :to="{name: 'dashboard.abtests.edit', params: {id: test.id}}" class="discrete link">
          <app-icon name="wrench"></app-icon>&nbsp;
          <translate translate-context="*/*/*/Verb">Edit</translate>
        </router-link>
      </div>
    </div>

    <div class="stackable row mt-regular" v-if="expanded">
      <div>
        <h5>
          <translate translate-context="*/*/*">Transformations</translate>
        </h5>
        <ul class="pl-regular">
          <li v-for="t in test.transformations" :key="t.name" class="my-tiny discrete">
            {{ parseInt(getWeight(t.weight, test.transformations.length) * 100) }}% {{ t.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import truncate from 'lodash/truncate'
import DownloadLink from '@/components/DownloadLink'
import http from '@/http'

import {prettyDate, isPast, isFuture, differenceInSeconds} from '@/time'
import {stringToArray} from '@/parsing'

export default {
  props: ['test'],
  components: {
    DownloadLink
  },
  data () {
    return {
      users: "",
      errors: {},
      showAssignModal: false,
      isAssigning: false,
      stringToArray,
      prettyDate,
      isFuture,
      isPast,
      expanded: false,
      truncate,
    }
  },
  computed: {
    labels () {
      return {
        startDate: this.$pgettext('*/*/*', 'Start date'),
        endDate: this.$pgettext('*/*/*', 'End date'),
        reportTitle: this.$pgettext('Abtests/*/*', "Daily reports are sent to this email address"),
      }
    },
    weekdays () {
      return {
        0:  this.$pgettext('*/*/*', 'Mondays'),
        1:  this.$pgettext('*/*/*', 'Tuesdays'),
        2:  this.$pgettext('*/*/*', 'Wednesdays'),
        3:  this.$pgettext('*/*/*', 'Thursdays'),
        4:  this.$pgettext('*/*/*', 'Fridays'),
        5:  this.$pgettext('*/*/*', 'Saturdays'),
        6:  this.$pgettext('*/*/*', 'Sundays'),
      }
    },
    expectedProgress () {
      let timeProgress = differenceInSeconds(new Date(), this.test.start) / differenceInSeconds(this.test.end, this.test.start)
      return Math.min(timeProgress, 1)
    },
  },
  methods: {
    getWeight (t, total) {
      if (t != null)  {
        return t
      }
      return 1 / total
    },
    async assign () {
      this.errors = {}
      this.isAssigning = true
      let payload = {
        objects: this.stringToArray(this.users),
        action: "set_abtest",
        data: this.test.id,
        filters: {},
      }
      let response
      try {
        response = await http.post('it/users/action', payload)
        this.users = ""
        this.$emit("refresh")
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isAssigning = false
        if (response && !response.backendErrors) {
          this.showAssignModal = false
        }
      }
    },
  }
}
</script>