<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <a href="" @click.prevent="updateSort" class="discrete">
    <slot></slot>
    <template v-if="isActive">
      <app-icon v-if="currentSortOrder === 'DESC'" name="menu-down"></app-icon>
      <app-icon v-else name="menu-up"></app-icon>
    </template>
    <app-icon v-else name="noop"></app-icon>
  </a>
</template>
<script>
export default {
  props: {
    value: String,
    currentSort: String,
  },
  computed: {
    isActive () {
      return this.currentSortField === this.value;
    },
    currentSortOrder () {
      return this.currentSort.slice(0, 1) === '-' ? 'DESC' : 'ASC'
    },
    currentSortField () {
      if (this.currentSort.slice(0, 1) === '-') {
        return this.currentSort.slice(1)
      }
      return this.currentSort
    }
  },
  methods: {
    updateSort () {
      let newSort = null
      if (this.isActive) {
        newSort = this.currentSortOrder === 'DESC' ? this.value : `-${this.value}`
      } else {
        newSort = this.value
      }
      this.$emit('updated', newSort)
    }
  }
}
</script>