<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->

<template>
  <div class="main">
    <section class="bordered padded" v-if="!id">
      <form class="stackable row" @submit.prevent>
        <div class="field">
          <label for="search">
            <translate translate-context="*/*/*/Verb">Search</translate>
          </label>
          <input id="search" v-model="filters.q" :placeholder="searchPlaceholder" :title="searchPlaceholder">
        </div>
        <div class="field">
          <label for="status">
            <translate translate-context="*/*/*/Noun">Status</translate>
          </label>
          <select id="status" v-model="filters.online">
            <option value="">
              <translate translate-context="*/Field/Value">Any</translate>
            </option>
            <option value="true"><translate translate-context="*/*/Status">Online</translate></option>
            <option value="false"><translate translate-context="*/*/Status">Offline</translate></option>
          </select>
        </div>
        <div class="field">
          <label for="country"><translate translate-context="*/*/*">Country</translate></label>
          <select id="country" v-model="filters.country">
            <option value=""><translate translate-context="*/*/*">All countries</translate></option>
            <option
              :value="country.code"
              v-for="country in meta.countries"
              :key="country.code">{{ country.name }}</option>
          </select>
        </div>
        <div class="field">
          <label for="version"><translate translate-context="*/*/*">Version</translate></label>
          <select id="version" v-model="filters.version">
            <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
            <option
              :value="version"
              v-for="version in meta.versions"
              :key="version.id">{{ version }}</option>
          </select>
        </div>
        <div class="field">
          <label for="error"><translate translate-context="*/*/*">Issues</translate></label>
          <select id="error" v-model="filters.error">
            <option value=""><translate translate-context="*/Field/Value">All machines</translate></option>
            <option value="true"><translate translate-context="*/*/*">Any issue</translate></option>
            <option value="false"><translate translate-context="*/*/*">No issue</translate></option>
            <option :value="issue" v-for="issue in meta.issues" :key="issue">{{ issue }}</option>
          </select>
        </div>
        <div class="field">
          <label for="connected-app"><translate translate-context="*/*/*">Connected app</translate></label>
          <select id="connected-app" v-model="filters.connected_app">
            <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
            <option :value="app.name" v-for="app in meta.connected_apps" :key="app.name">{{ app.label }}</option>
          </select>
        </div>
      </form>
    </section>
    <section class="v-spaced bordered padded" v-if="isLoading">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading machines…</translate></span>
      </loading-area>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results && results.count === 0">
      <empty-state>
        <template v-slot:controls >
          · <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
            <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
          </a>
        </template>
      </empty-state>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results">
      <header class="controls mb-regular">
        <h2>
          <translate
            :translate-params="{count: results.count}"
            translate-plural="%{ count } matching machines"
            :translate-n="results.count"
            translate-context="*/*/Title">
            %{ count } matching machine
          </translate>
        </h2>
        <router-link :to="{name: 'dashboard.machines', params: {group: $store.state.selectedGroupId}}" v-if="id" >
          <translate translate-context="*/*/*/Action">Back to machines list</translate>
        </router-link>
        <a href="" v-else-if="hasFilters" @click.prevent="filters = {...initialFilters}">
          <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
        </a>
      </header>
      <div class="machine-row results-row">
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="name">
            <translate translate-context="*/*/*">Machine</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="last_seen">
            <translate translate-context="*/*/*/Noun">Status</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="local_ip">
            <translate translate-context="*/*/*">Local IP</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="version">
            <translate translate-context="*/*/*">Version</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="capture_device">
            <translate translate-context="*/*/*">Capture device</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="playback_device">
            <translate translate-context="*/*/*">Playback device</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="connected_app">
            <translate translate-context="*/*/*">Connected app</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="country">
            <translate translate-context="*/*/*">Country</translate>
          </sort-link>
        </strong>
      </div>
      <machine-row
        :key="machine.name"
        :obj="machine"
        :meta="meta"
        @filter="filters[$event.name] = $event.value"
        :expand="machine.name === expandedRow || machine.name === id"
        @expanded="expand(machine.name, $event)"
        v-for="machine in results.results">
      </machine-row>
    </section>
    <pagination
      v-if="results && results.count > pageSize"
      @page-changed="filters.page = $event"
      :current="filters.page"
      :paginate-by="pageSize"
      :total="results.count"
      ></pagination>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import forOwn from 'lodash/forOwn'
import http from '@/http'

import Pagination from '@/components/Pagination'
import SortLink from '@/components/SortLink'
import MachineRow from '@/components/MachineRow'

const initialFilters = {
  country: "",
  online: "",
  version: "",
  connected_app: "",
  error: "",
  o: '-last_seen',
  page: 1,
  q: '',
}

function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}
export default {
  props: {
    id: {required: false},
    group: {},
  },
  components: {
    Pagination,
    SortLink,
    MachineRow,
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      results: null,
      expandedRow: null,
      meta: {countries: [], transformations: [], labels: []},
      filters: {...initialFilters, ...this.$route.query},
      initialFilters: initialFilters,
      pageSize: 30,
    }
    if (this.id) {
      f.filters.q = this.id
    }
    return f
  },
  async created () {    
    let fetch = this.fetchData(this.filters)
    this.meta = (await http.get("it/meta", {params: {group: this.group}})).data
    await fetch
  },
  computed: {
    hasFilters () {
      return !isEqual(initialFilters, this.filters)
    },
    allFilters () {
      return {...this.filters}
    },
    searchPlaceholder () {
      return this.$pgettext('*/*/Search.Placeholder', 'IP, ID')
    }
  },
  methods: {
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      let params = {...filters, page_size: this.pageSize, group: this.group}
      try {
        response = await http.get('it/machines', {params})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    expand (id, value) {
      if (value) {
        this.expandedRow = id
      } else {
        this.expandedRow = null
      }
    },
    async refresh () {
      this.expandedRow = null
      if (!this.id) {
        this.$router.push({query: toQueryString(this.filters)})
      }
      window.scrollTo(0, 0);
      await this.fetchData(this.filters)
    }
  },
  watch: {
    allFilters: {
      deep: true,
      handler: debounce(
        async function (n, o) {
          if (n.page === o.page) {
            this.filters.page = 1
          }
          await this.refresh()
        }, 250
      )
    },
    id: {
      async handler (v) {
        this.filters.q = v
        this.results = null
        if (v) {
          this.expandedRow = v
        }
      },
    }
  }
}
</script>
