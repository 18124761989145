<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div :class="['results-row machine-row', {expanded: expand}]">
    <div class="monospace">
      <a href="" class="discrete" @click.prevent="$emit('expanded', !expand)" :title="obj.hostname || obj.name">
        <app-icon v-if="expand" name="magnify-minus-outline"></app-icon>
        <app-icon v-else name="magnify-plus-outline"></app-icon>
        <template v-if="obj.hostname">
          {{ obj.hostname.slice(0, 10) }}
        </template>
        <template v-else>
          {{ obj.name.slice(0, 8) }}
        </template>
      </a>
    </div>
    <div :title="onlineTitle" class="status">
      <span class="controls">
        <span :class="[{online: online}, {offline: !online}]">
          <app-icon v-if="online" name="power" />
          <app-icon v-else name="close"/>&nbsp;
          <span v-if="online"><translate translate-context="*/*/Status">On</translate></span>
          <span v-else><translate translate-context="*/*/Status">Off</translate></span>
        </span>
        <span v-if="obj.issues.length > 0" :title="errorLabel">
          <app-icon
            name="alert-circle-outline"
            class="warning"
            @click.prevent="$emit('expanded', !expand)"></app-icon>
        </span>
      </span>
    </div>
    <div class="ellipsis">
      {{ obj.ip.local }}      
    </div>
    <div class="ellipsis">
      <a 
        href=""
        class="discrete"
        @click.prevent="$emit('filter', {name: 'version', value: obj.version})">
        {{ obj.version }}      
      </a>
    </div>
    <div class="ellipsis">
      <span v-if="obj.stream" :title="obj.stream.capture_device">
        {{ obj.stream.capture_device }}
      </span>
    </div>
    <div class="ellipsis">
      <span v-if="obj.stream" :title="obj.stream.playback_device">
        {{ obj.stream.playback_device }}
      </span>
    </div>
    <div class="ellipsis">
      <a 
        v-if="obj.stream && obj.stream.connected_app"
        :title="obj.stream.connected_app"
        href=""
        class="discrete"
        @click.prevent="$emit('filter', {name: 'connected_app', value: obj.stream.connected_app})">
        {{ connectedAppLabel }}
      </a>
      <span v-else-if="obj.stream && connectedAppLabel === 'N/A'" :title="connectedAppOldVersionLabel">
        {{ connectedAppLabel }}
      </span>
      <span v-else-if="obj.stream" :title="connectedAppLastSeenLabel">
        <app-icon name="alert-circle-outline"></app-icon>
      </span>
    </div>
    <div class="ellipsis">
      <a 
        href=""
        class="discrete monospace"
        :title="obj.ip.country_name"
        @click.prevent="$emit('filter', {name: 'country', value: obj.ip.country_code})">
        {{ obj.ip.country_code }}
      </a>
    </div>
    <div class="detail stackable row" v-if="expand">
      <div class="heavily padded">
        <div class="controls mb-regular">
          <h4><translate translate-context="*/*/Title">Machine information</translate></h4>
          <span>
            <app-button @click.prevent="refresh" :is-loading="isRefreshing" class="link my-0">
              <translate translate-context="*/*/*/Verb">Refresh</translate>
            </app-button>
            ·
            <router-link :to="{name: 'dashboard.machines.detail', params: {id: obj.name, group: $store.state.selectedGroupId}}">
              <app-icon name="link"></app-icon>
            </router-link>
          </span>
        </div>
        <device-info :hide="['name', 'ip.local', 'version', 'session_locked']" :machine="obj"></device-info>
        <h4><translate translate-context="*/*/*">Users</translate></h4>
        <p v-if="obj.users.length === 0">
          <translate translate-context="*/*/*">No known users yet.</translate>
        </p>
        <table class="simple" v-else>
          <thead>
            <th>
              <translate translate-context="*/*/*">ID</translate>
            </th>
            <th>
              <translate translate-context="*/*/*">Last seen</translate>
            </th>
          </thead>
          <tbody>
            <tr v-for="user in obj.users" :key="user.name">
              <td>
                <router-link
                  :to="{name: 'dashboard.users.detail', params: {id: user.session_hash, group: $store.state.selectedGroupId}}">
                  <template v-if="user.name">
                    {{ user.name }}
                  </template>
                  <template v-else>
                    {{ user.session_hash.slice(0, 8) }}
                  </template>
                </router-link>
              </td>
              <td>
                <time class="discrete" :datetime="user.last_update" :title="user.last_update">
                  {{ ago(user.last_update) }}
                </time>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="heavily padded">
        <h4><translate translate-context="*/*/Title">Machine issues</translate></h4>
        <p v-if="obj.issues.length === 0">
          <translate translate-context="*/*/*">No issues were detected on this machine.</translate>
        </p>
        <p v-else>
          <app-icon
            name="alert-circle-outline"
            class="warning"></app-icon>&nbsp;
          <translate
            translate-context="*/*/*"
            :translate-params="{count: obj.issues.length}"
            translate-plural="%{ count } issues were detected on this machine."
            :translate-n="obj.issues.length">
            %{ count } issue was detected on this machine.
          </translate>
        </p>
        <issue-message v-for="(issue, idx) in obj.issues" :issue="issue" :key="idx"></issue-message>
      </div>
    </div>
  </div>
</template>
<script>
import semverLt from 'semver/functions/lt'
import uniq from 'lodash/uniq'
import isAfter from 'date-fns/isAfter'
import subSeconds from 'date-fns/subSeconds'

import http from  '@/http'

import { ago } from '@/time'

import DeviceInfo from './DeviceInfo'
import IssueMessage from './IssueMessage'

export default {
  props: {
    obj: {},
    meta: {},
    expand: {type: Boolean, default: false},
  },
  components: {
    DeviceInfo,
    IssueMessage
  },
  data () {
    return {
      isRefreshing: false,
      isLoading: false,
      errors: {},
      lastUpdate: new Date(),
      config: null,
      configApplied: null,
    }
  },
  computed: {
    errorLabel () {
      return this.$pgettext("*/*/Tooltip", "This machine has some configuration issues, expand the row for more information")
    },
    online () {
      let d = new Date(this.obj.last_update)
      let b = subSeconds(new Date(), 300)
      return isAfter(d, b)
    },
    onlineTitle () {
      let msg = this.$pgettext("*/*/Tooltip", "Last seen on %{ date }")
      return this.$gettextInterpolate(msg, {date: this.obj.last_update})
    },
    transformationName () {
      if (this.meta.transformations.length === 0) {
        return 0
      }
      let t = this.meta.transformations.filter((t) => {
        return t.id === this.obj.stream.transformation
      })
      if (t.length > 0) {
        return t[0].name
      }
      return null
    },
    captureDevices () {
      let devices = [...this.obj.capture_devices]
      if (this.config.capture_device) {
        devices.push(this.config.capture_device)
      }
      return uniq(devices)
    },
    playbackDevices () {
      let devices = [...this.obj.playback_devices]
      if (this.config.playback_device) {
        devices.push(this.config.playback_device)
      }
      return uniq(devices)
    },
    connectedAppOldVersionLabel () {
      return this.$pgettext("*/*/Tooltip", "This machine is running an old version of Alta Call, please upgrade to a newer version to use this feature.")
    },
    connectedAppLastSeenLabel () {
      let msg
      if (this.obj.stream.connected_app_last_update) {
        msg = this.$pgettext("*/*/Tooltip", "This machine was connected to an application %{ ago }.")
        return this.$gettextInterpolate(msg, {ago: ago(this.obj.stream.connected_app_last_update)})
      } else {
        return this.$pgettext("*/*/Tooltip", "This machine was connected never connected to an application.")
      }
    },
    connectedAppLabel() {
      let name = this.obj.stream.connected_app
      if (semverLt(this.obj.version, '0.24.4')) {
        return 'N/A'
      }
      if (!name) {
        return null
      }
      if (!this.meta || !this.meta.connected_apps) {
        return name
      }
      return this.meta.connected_apps.filter(
        (a) => { return a.name === name}
      ).map((a) => { return a.label })[0] || name
    }
  },
  methods: {
    ago,
    async refresh () {
      this.isRefreshing = true
      try {
        let response = await http.get(`it/machines/${this.obj.name}`)
        this.$emit("updated", response.data)
      } finally {
        this.isRefreshing = false
      }
    },
  },
  watch: {
    obj: {
      handler (v) {
        this.lastUpdate = new Date()
        this.config = {...v.config}
      },
      immediate: true,
    }
  }
}
</script>