<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div v-if='maxPage > 1' class="pagination">
    <a href
      v-if="current > 1"
      @click.prevent.stop="selectPage(current - 1)">
        <app-icon name="chevron-left"></app-icon>
        <translate translate-context="*/*/Pagination.Link">Previous</translate>
      </a>
    <template v-if="!compact">
      <template v-for="(page, idx) in pages">
        <a href
          v-if="page != 'skip'"
          :key="idx"
          @click.prevent.stop="selectPage(page)"
          :class="[{'active': page === current}]">
          {{ page }}
        </a>
        <a v-else :key="`d-${idx}`">
          …
        </a>
      </template>
    </template>
    <a
      v-if="current + 1 <= maxPage"
      href=""
      @click.prevent.stop="selectPage(current + 1)"
      >
        <translate translate-context="*/*/Pagination.Link">Next</translate>
      <app-icon name="chevron-right"></app-icon>
    </a>
  </div>
</template>

<script>
import range from "lodash/range"
import uniq from "lodash/uniq"
import sortBy from "lodash/sortBy"

export default {
  props: {
    current: { type: Number, default: 1 },
    paginateBy: { type: Number, default: 25 },
    total: { type: Number },
    compact: { type: Boolean, default: false }
  },
  computed: {
    pages: function() {
      let prange = 2
      let current = this.current
      let beginning = range(1, Math.min(this.maxPage, 1 + prange))
      let middle = range(
        Math.max(1, current - prange + 1),
        Math.min(this.maxPage, current + prange)
      )
      let end = range(this.maxPage, Math.max(1, this.maxPage - prange))
      let allowed = beginning.concat(middle, end)
      allowed = uniq(allowed)
      allowed = sortBy(allowed, [
        e => {
          return e
        }
      ])
      let final = []
      allowed.forEach(p => {
        let last = final.slice(-1)[0]
        let consecutive = true
        if (last === "skip") {
          consecutive = false
        } else {
          if (!last) {
            consecutive = true
          } else {
            consecutive = last + 1 === p
          }
        }
        if (consecutive) {
          final.push(p)
        } else {
          if (p !== "skip") {
            final.push("skip")
            final.push(p)
          }
        }
      })
      return final
    },
    maxPage: function() {
      return Math.ceil(this.total / this.paginateBy)
    }
  },
  methods: {
    selectPage: function(page) {
      if (page > this.maxPage || page < 1) {
        return
      }
      if (this.current !== page) {
        this.$emit("page-changed", page)
      }
    }
  }
}
</script>