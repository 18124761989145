<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->

<template>
  <section class="tiny narrow main transparent">
    <section class="v-spaced bordered padded" v-if="$store.state.groups.length > 1">
      <div class="mb-regular">
        <div class="field">
          <h3>
            <label for="group">
              <translate translate-context="*/*/*/Noun">Selected group</translate>
            </label>
          </h3>
          <p>
            <translate translate-context="*/*/*">
              Alta Call resources such as users, machines, labels and A/B tests belong to groups. You are currently browsing resources for group:
            </translate>
          </p>
          <select
            name="group"
            id="group"
            :value="$store.state.selectedGroupId"
            @change="$store.commit('selectBestGroup', $event.target.value); $router.push({name: 'dashboard', params: {group: $event.target.value}})">
            <option
              v-for="group in $store.state.groups"
              :key="group.id" :value="group.id">{{ group.name }}</option>
          </select>
        </div>
      
      </div>
    </section>
    <section class="v-spaced bordered padded">
      <div class="controls mb-regular">
        <h2>
          <app-icon name="account-multiple" />&nbsp;
          <translate translate-context="*/*/*">Users</translate>
        </h2>
        <router-link :to="{name: 'dashboard.users', params: {group: $store.state.selectedGroupId}}">
          <translate translate-context="*/*/*/Action">View all</translate>
        </router-link>
      </div>
      <table class="no-headers">
        <tbody>
          <tr v-for="(config, idx) in getConfigs('users')" :key="idx" >
            <td>
              <router-link :to="{name: `dashboard.${config.type}`, query: config.params, params: {group: $store.state.selectedGroupId}}">
                {{ config.label }}
              </router-link>
            </td>
            <td>
              <app-icon v-if="stats[config.type][config.id] === null" name="refresh" class="rotating"></app-icon>
              <template v-else>{{ stats[config.type][config.id] }}</template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="warning message" v-if="stats['users']['online']">
        <h4>
          <translate translate-context="*/*/*">Some issues need to be fixed</translate>
        </h4>
        <p>
          <translate translate-context="*/*/*">One or more users have setup or configuration issues that prevent Alta Call from running properly.</translate>
        </p>
        <router-link :to="{name: 'dashboard.issues', query: {online: true}, params: {group: $store.state.selectedGroupId}}">
          <translate translate-context="*/*/*/Action">Browse and fix issues</translate>
        </router-link>
      </div>
    </section>

    <section class="v-spaced bordered padded">
      <div class="controls mb-regular">
        <h2>
          <app-icon name="laptop" />&nbsp;
          <translate translate-context="*/*/*">Machines</translate>
        </h2>

        <router-link :to="{name: 'dashboard.machines', params: {group: $store.state.selectedGroupId}}">
          <translate translate-context="*/*/*/Action">View all</translate>
        </router-link>
      </div>
      <table class="no-headers">
        <tbody>
          <tr v-for="(config, idx) in getConfigs('machines')" :key="idx" >
            <td>
              <router-link :to="{name: `dashboard.${config.type}`, query: config.params, params: {group: $store.state.selectedGroupId}}">
                {{ config.label }}
              </router-link>
            </td>
            <td>
              <app-icon v-if="stats[config.type][config.id] === null" name="refresh" class="rotating"></app-icon>
              <template v-else>{{ stats[config.type][config.id] }}</template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="warning message" v-if="onlineOutdatedMachines > 0">
        <h4>
          <translate translate-context="*/*/*" :translate-params="{count: onlineOutdatedMachines}">%{ count } machines are running an unsupported Alta Call version</translate>
        </h4>
        <p>
          <translate translate-context="*/*/*" :translate-params="{latest: versions[0].version}">The latest version is %{ latest }. Please update Alta Call to benefit from new features, bugfixes and enhancements.</translate>
        </p>
        <div class="controls">
          <router-link :to="`/downloads#${versions[0].version}`">
            <translate translate-context="*/*/*/Action">Changelog and download</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.outdated-machines', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*/Action">View outdated machines</translate>
          </router-link>
        </div>
      </div>
    </section>
    <section class="v-spaced bordered padded">
      <div class="controls mb-regular">
        <h2>
          <app-icon name="chart-box" />&nbsp;
          <translate translate-context="*/*/*">A/B Tests</translate>
        </h2>

        <router-link :to="{name: 'dashboard.abtests', params: {group: $store.state.selectedGroupId}}">
          <translate translate-context="*/*/*/Action">View all</translate>
        </router-link>
      </div>
      <p>
        <router-link :to="{name: 'dashboard.requests', params: {group: $store.state.selectedGroupId}}">
          <translate translate-context="*/*/*">
            Request a new A/B test
          </translate>
        </router-link>
      </p>
      <loading-area v-if="isLoadingAbtests" :is-loading="isLoadingAbtests">
        <span><translate translate-context="*/*/Loader">Loading A/B tests…</translate></span>
      </loading-area>
      <p v-else-if="!abtests || abtests.count === 0">
        <translate translate-context="*/*/*">
          No A/B test is currently running.
        </translate>
      </p>
      <template v-else>
        <abtest-card
          class="mb-regular card"
          :test="test"
          :key="test.id" v-for="test in abtests.results"></abtest-card>
      </template>
    </section>
  </section>
</template>

<script>
import http from '@/http'
import AbtestCard from '@/components/AbtestCard'

export default {
  components: {
    AbtestCard
  },
  props: {
    group: {}
  },
  data () {
    return {
      isLoadingAbtests: false,
      abtestsErrors: {},
      abtests: null,
      stats: {
        users: {},
        machines: {},
      },
      polling: null,
      pollingInterval: 60 * 1000 * 5,
      versions: [],
      onlineOutdatedMachines: 0,
    }
  },
  async created () {
    this.statConfigs.forEach(c => {
      this.stats[c.type][c.id] = null
    })
    let a = this.fetchStats()
    let b = this.fetchAbtests()
    let c = this.fetchVersionsAndOnlineOutdatedMachines()
    await a
    await b
    await c
    this.polling = setInterval(() => {
      this.statConfigs.forEach(c => {
        this.stats[c.type][c.id] = null
      })
      this.fetchStats()
    }, this.pollingInterval)
  },
  beforeUnmount () {
    clearInterval(this.polling)
  },
  computed: {
    statConfigs () {
      return [
        {
          type: "users",
          id: "online",
          params: {online: "true"},
          label: this.$pgettext('*/*/Status', 'Online'),
        },
        {
          type: "users",
          id: "online_issues",
          params: {error: "true", online: "true"},
          label: this.$pgettext('*/*/Status', 'Online with issues'),
        },
        {
          type: "users",
          id: "total",
          params: {},
          label: this.$pgettext('*/*/*/Noun', 'Total'),
        },
        {
          type: "machines",
          id: "online",
          params: {online: "true"},
          label: this.$pgettext('*/*/Status', 'Online'),
        },
        {
          type: "machines",
          id: "online_issues",
          params: {error: "true", online: "true"},
          label: this.$pgettext('*/*/Status', 'Online with issues'),
        },
        {
          type: "machines",
          id: "total",
          params: {},
          label: this.$pgettext('*/*/*/Noun', 'Total'),
        },
      ]
    }
  },
  methods: {
    async fetchStats () {
      if (!this.$store.state.selectedGroupId) {
        return
      }
      const promises = this.statConfigs
      // start all requests in parallel to reduce loading time
      promises.forEach((p) => {
        let params = {...p.params, limit: 1, group: this.group}
        p.promise = http.get(`it/${p.type}`, {params})
      })
      // then await the promises
      promises.forEach(async (p) => {
        let r = await p.promise
        this.stats[p.type][p.id] = r.data.count
      })
    },
    async fetchAbtests () {
      let response
      this.isLoadingAbtests = true
      this.abtests = {}
      this.abtestsErrors = {}
      try {
        response = await http.get('it/abtests', {params: {ongoing: 'true', group: this.group}})
        this.abtests = response.data
      } catch (e) {
        this.abtestsErrors = e.backendErrors
      } finally {
        this.isLoadingAbtests = false
      }
    },
    async fetchVersionsAndOnlineOutdatedMachines () {
      let response
      this.versions = []
      this.onlineOutdatedMachines = 0
      try {
        response = await http.get('https://apps-download.alta-voce.tech/vmocall-any-AU5IlnnRQfPHDt69iBdzXNXjjNjZWMcH/dirlist_json.php')
        this.versions = response.data
        let onlineResponse = await http.get('it/outdated-machines', {params: {group: this.group}})
        this.onlineOutdatedMachines = onlineResponse.data.count
      } catch {
        this.versions = []
        this.onlineOutdatedMachines = 0
      }
    },
    getConfigs (type) {
      return this.statConfigs.filter(c => {
        return c.type === type
      })
    }
  }
}
</script>
