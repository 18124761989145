<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div>
    <router-view :key="group" :group="group" v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import http from '@/http'
export default {
  props: {
    group: {}
  },
  components: {},
  async created () {
    await this.$store.dispatch("fetchGroups", http)
    await this.$store.dispatch("fetchOrganization", http)
    if (this.group) {
      this.$store.commit("selectBestGroup", this.group)
      if (this.group != this.$store.state.selectedGroupId) {
        // group from URL was not found in existing group,
        // redirect to home
        this.$router.push({name: "dashboard", params: {group: this.$store.state.selectedGroupId}})
      }
      return
    } else if (this.$store.state.selectedGroupId) {
      this.$store.commit("selectBestGroup", this.$store.state.selectedGroupId)
    } else {
      this.$store.commit("selectBestGroup", null)
    }
    this.$router.push({
      name: this.$route.name,
      params: {group: this.$store.state.selectedGroupId},
      query: this.$route.query,
    })
  }
}
</script>
